﻿ export const page_text_ca={
	
	// Servicios
	sati_name:"SATI",
	sati_desc:"Servei d'acompanyament en teràpia inhalada",
	satib_name:"SATIB",
	satib_desc:"Servei bàsic d'acompanyament en teràpia inhalada",
	iretia_name:"IRETIA",
	iretia_desc:"Intervenció ràpida en teràpia inhalada aguda",
	cepoc_name:"CEPOC",
	cepoc_desc:"Cribratge de Malaltia Pulmonar Obstructiva MPOC",
	probio_name:"ProbioMentor",
	probio_desc:"Formació, mentoria i estratègia en Probiòtics",
	probio_more:"Dossier",
	probio_video:"Presentació",

	rcm_name:"RCM",
	rcm_desc:"Control de risc cardio metabòlic",
	adir_name:"ADIR",
	adir_desc:"Ajust de dosi en insuficiència renal",

	// Navigation
	nav_home:"Inici",
	nav_patients:"Pacients",
	nav_services:"Serveis",
	nav_profile:"Perfil",
	nav_videos:"Formació",
	nav_calendar:"Agenda",
	nav_reports:"Consultes",
	nav_language:"Idioma",
	nav_language_ca:"Català",
	nav_language_es:"Español",
	nav_logout:"Tancar sessió",
	nav_owner:"Mode titular",

	// Login
    login_title:"Inici de sessió",
	login_user_placeholder:"Usuari o email",
	login_password_placeholder:"Contrasenya",
	login_button:"Autentificar",
	login_error_user:"Usuari o contrasenya no vàlids.",
	login_error_server:"Error de conexió.",


	login_password_forgotten:"Si ha oblidat la seva contrasenya, introdueixi la seva adreça de correu electrònic i premi [Recuperar contrasenya]. Rebrà un missatge amb un codi de recuperació.",
	login_send_password:"Recuperar contrasenya.",
	login_error_recover:"Error en iniciar el procés de recuperació.",
	login_error_email:"Adreça de recuperació no vàlida.",
	login_recover_ok:"Codi enviat a l’adreça de correu electrònic.",

	update_password_title:"Actualitzar contrasenya",
  	update_password_info:"Per motius de seguretat, heu de crear una contrasenya nova per accedir a l'aplicatiu.",
  	update_password_placeholder:"Contrasenya nova",
  	update_repassword_placeholder:"Confirma la contrasenya nova",
  	update_password_button:"Actualitzar contrasenya",
  	update_password_must_1:"Ha de contenir almenys un nombre.",
  	update_password_must_2:"Ha de contenir almenys una lletra majúscula.",
  	update_password_must_3:"Ha de contenir almenys un símbol.",
  	update_password_must_4:"Has de confirmar la contrasenya.",
	update_error:"Problema actualitzant contrasenya. Si us plau, torneu a intentar-ho abans de contactar amb Farmaval.",
	
	register_title:"Benvingut!",
	register_info:"Per registrar la vostra farmàcia, si us plau, introduïu un email, una contrasenya d'accés i el codi de registre subministrat pel vostre delegat.<br><br>La contrasenya ha de:",
	register_email:"Email",
	register_password:"Contrasenya d'accés",
	register_repassword:"Confirmar contrasenya",
	register_password_must_1:"Contenir almenys un nombre.",
	register_password_must_2:"Contenir almenys una lletra majúscula.",
	register_password_must_3:"Contenir almenys un símbol.",
	register_password_must_4:"Has de confirmar la contrasenya.",
	register_pharm:"nom de la farmàcia",
	register_code:"Codi de Prodeco",
	register_pharm_button:"Registrar farmàcia",
	register_error_func:"S'ha produït un problema inesperat. Si us plau, poseu-vos en contacte amb Farmaval.",
	register_error_email:"El correu electrònic ja està registrat.",
	register_error_code:"El codi no és vàlid.",
	
	register_notify_title:"Registre correcte",
	register_notify_info:"Li hem enviat un email amb les dades de registre (comproveu la safata d'spam si no l'heu rebut). Si us plau, inicieu sessió per continuar amb el procés d'alta.",	register_continue_button:"Iniciar sessió",

	alert_code_title:"Mode Titular",
  	alert_code_info:"Per accedir al mode titular heu d'introduir la contrasenya:",
  	alert_code_wrong:"Contrasenya incorrecta.",
	alert_code_recover:"He oblidat la contrasenya",
	alert_code_recover_info:"S'enviarà un email a l'adreça de correu del titular.",
	alert_code_recover_email:"S'ha enviat un email amb la nova contrasenya a l'adreça: ",
	alert_code_recover_error:"S\'ha produït un error en enviar el correu amb la nova contrasenya.",

	// Formación
	formation_sati:"Servei SATI",
	formation_iretia:"Servei IRETIA",
	formation_cepoc:"Servei CEPOC",
	formation_all:"Sessions de valor",
	
	videos_label:"Videos",
	all_videos_label:"Para el responsable",
	team_videos_label:"Para el equipo",
	material_label:"Material adicional",
	video_iretia_1:"1. Taller inhaladores Presurizados.",
	video_iretia_2:"2. Taller inhaladores Camara de inhalación.",
	video_iretia_3:"3. Taller inhaladores polvo seco monodosis.",
	video_iretia_4:"4. Taller inhaladores polvo seco multidosis con depósito.",
	video_iretia_5:"5. Taller de inhaladores polvo seco con blíster.",
	video_iretia_6:"6. Conceptos de aerosolterapia y distribución de partículas.",
	video_iretia_7:"7. La técnica inhalada.",
	link_iretia_1:"https://player.vimeo.com/video/838398122",
	link_iretia_2:"https://player.vimeo.com/video/688355173",
	link_iretia_3:"https://player.vimeo.com/video/684574347",
	link_iretia_4:"https://player.vimeo.com/video/688361792",
	link_iretia_5:"https://player.vimeo.com/video/688367203",
	link_iretia_6:"https://player.vimeo.com/video/688371703",
	link_iretia_7:"https://player.vimeo.com/video/688623093",
	material_iretia_1:"1- Consenso asma pediatría 2021 REGAP.",
	material_iretia_2:"2- Dossier formativo.",
	material_iretia_3:"3- Informe dispositivos de polvo seco unidosis.",
	material_iretia_4:"4- Informe dispositivos de polvo seco multidosis.",
	material_iretia_5:"5-Informe dispositivos presurizados sin cámara.",
	material_iretia_6:"6- Informe dispositivos presurizados con cámara.",
	material_iretia_7:"7- Informe dispositivos presurizados con cámara en niños y lactantes.",
	file_iretia_1:"https://www.farmaval.net/docs/8-%20Consenso%20asma%20pediatria%202021%20REGAP.pdf",
	file_iretia_2:"https://www.farmaval.net/docs/9-Dossier%20formativo.pdf",
	file_iretia_3:"https://www.farmaval.net/docs/iretia/ca/pols_seca_unidosis.pdf",
	file_iretia_4:"https://www.farmaval.net/docs/iretia/ca/polvo_seco_multidosis.pdf",
	file_iretia_5:"https://www.farmaval.net/docs/iretia/ca/presurizados_sin_c%C3%A1mara.pdf",
	file_iretia_6:"https://www.farmaval.net/docs/iretia/ca/presurizados_con_c%C3%A1mara.pdf",
	file_iretia_7:"https://www.farmaval.net/docs/iretia/ca/presurizados_c%C3%A1mara_lactantes.pdf",

	video_sati_1:"1. Una historia y un sueño.",
	video_sati_2:"2. ¿Por qué es necesario el servicio SATI?",
	video_sati_3:"3. Nuestro rol como profesionales sanitarios.",
	video_sati_4:"4. Taller inhaladores Presurizados.",
	video_sati_5:"5. Taller inhaladores Camara de inhalación.",
	video_sati_6:"6. Taller inhaladores polvo seco monodosis.",
	video_sati_7:"7. Taller inhaladores polvo seco multidosis con depósito.",
	video_sati_8:"8. Taller de inhaladores polvo seco con blíster.",
	video_sati_9:"9. Taller de inhaladores: Material de soporte.",
	video_sati_10:"10. Conclusiones y plan de acción para todo el equipo.",
	video_sati_11:"11. Conceptos de aerosolterapia y distribución de partículas.",
	video_sati_12:"12. La técnica inhalada.",
	video_sati_13:"13. El aparato respiratorio.",
	video_sati_14:"14. Patologia respiratoria y Principales diferencias entre Asma y EPOC.",
	video_sati_15:"15. El servicio SATI definición.",
	video_sati_16:"16. Funcionamiento de Farmaval App.",
	video_sati_17:"17. Recogida de datos generales del paciente.",
	video_sati_18:"18. SATI LOPD.",
	video_sati_19:"19. Diagnostico y farmacoterapia.",
	video_sati_20:"20. Cuestionarios de control de la patología respiratoria.",
	video_sati_21:"21. Educación sanitaria sobre la patología respiratoria.",
	video_sati_22:"22. Valoración de la técnica inhalada.",
	video_sati_23:"23. Formación y mejora de la técnica inhalada.",
	video_sati_24:"24. Los dispositivos de entrenamiento.",
	video_sati_25:"25. Control del flujo inspiratorio.",
	video_sati_26:"26. La prescripción farmacéutica y el talonario de prescripción.",
	video_sati_27:"27. Prescripción de la cámara A2A y mascarilla de inhalación.",
	video_sati_28:"28. Prescripción de InmunoRespir.",
	video_sati_29:"29. Prescripción para las mucosas orales afectadas.",
	video_sati_30:"30. Encuesta final al paciente.",
	video_sati_31:"31. Generación de informes y próxima cita.",
	video_sati_32:"32. Informe de paciente.",
	video_sati_33:"33. Informe de derivación por flujo insuficiente.",
	video_sati_34:"34. Otros informes de derivación.",
	video_sati_35:"35. Ofrecimiento del servicio en el mostrador.",
	video_sati_36:"36. Programar nuevas citas en la agenda.",
	video_sati_37:"37. Registro de las prescripciones aceptadas.",
	video_sati_38:"38. Sistema de recordatorios y mensajería.",
	video_sati_39:"39. Medidas Covid.",
	video_sati_40:"40. ¿Quién hay detrás de Farmaval?",
	video_sati_41:"41. Elección inhalador principal.",
	video_sati_42:"42. ¿Que ocurre si paciente usa dos inhaladores diferentes?",
	video_sati_43:"43. Paciente EPOC e inhalador monodosis polvo seco Handihaler.",
	video_sati_44:"44. Paciente EPOC con enfisema pulmonar e inhalador de niebla fina Respimat.",
	video_sati_45:"45. Paciente Asmático e inhalador de polvo seco Turbuhaler.",

	link_sati_1:"https://player.vimeo.com/video/684573034",
	link_sati_2:"https://player.vimeo.com/video/685392520",
	link_sati_3:"https://player.vimeo.com/video/688345206",
	link_sati_4:"https://player.vimeo.com/video/838398122",
	link_sati_5:"https://player.vimeo.com/video/688355173",
	link_sati_6:"https://player.vimeo.com/video/684574347",
	link_sati_7:"https://player.vimeo.com/video/688361792",
	link_sati_8:"https://player.vimeo.com/video/688367203",
	link_sati_9:"https://player.vimeo.com/video/688368760",
	link_sati_10:"https://player.vimeo.com/video/838405767",
	link_sati_11:"https://player.vimeo.com/video/688371703",
	link_sati_12:"https://player.vimeo.com/video/688623093",
	link_sati_13:"https://player.vimeo.com/video/688610397",
	link_sati_14:"https://player.vimeo.com/video/688626444",
	link_sati_15:"https://player.vimeo.com/video/688610899",
	link_sati_16:"https://player.vimeo.com/video/701189206",
	link_sati_17:"https://player.vimeo.com/video/684575488",
	link_sati_18:"https://player.vimeo.com/video/684576687",
	link_sati_19:"https://player.vimeo.com/video/685396491",
	link_sati_20:"https://player.vimeo.com/video/685397299",
	link_sati_21:"https://player.vimeo.com/video/685399122",
	link_sati_22:"https://player.vimeo.com/video/685399333",
	link_sati_23:"https://player.vimeo.com/video/688612675",
	link_sati_24:"https://player.vimeo.com/video/688459718",
	link_sati_25:"https://player.vimeo.com/video/688462696",
	link_sati_26:"https://player.vimeo.com/video/701189730",
	link_sati_27:"https://player.vimeo.com/video/688614103",
	link_sati_28:"https://player.vimeo.com/video/688629258",
	link_sati_29:"https://player.vimeo.com/video/688616792",
	link_sati_30:"https://player.vimeo.com/video/688617394",
	link_sati_31:"https://player.vimeo.com/video/688620317",
	link_sati_32:"https://player.vimeo.com/video/688617623",
	link_sati_33:"https://player.vimeo.com/video/688621404",
	link_sati_34:"https://player.vimeo.com/video/701190158",
	link_sati_35:"https://player.vimeo.com/video/688621835",
	link_sati_36:"https://player.vimeo.com/video/688618095",
	link_sati_37:"https://player.vimeo.com/video/688465095",
	link_sati_38:"https://player.vimeo.com/video/688466020",
	link_sati_39:"https://player.vimeo.com/video/685406481",
	link_sati_40:"https://player.vimeo.com/video/685413171",
	link_sati_41:"https://player.vimeo.com/video/838385660",
	link_sati_42:"https://player.vimeo.com/video/838386868",
	link_sati_43:"https://player.vimeo.com/video/891112561",
	link_sati_44:"https://player.vimeo.com/video/891123647",
	link_sati_45:"https://player.vimeo.com/video/891147645",

	material_sati_1:"1- Estudio EPOCONSUL.",
	material_sati_2:"2- Informe de resultados ASPIRA 2014.",
	material_sati_3:"3- Revisión sistemática 2016 sobre errores en terapia inhalada.",
	material_sati_4:"4- Programa SABINA sobreuso SABA.",
	material_sati_5:"5- MiniGEMA5.1.",
	material_sati_6:"6- GINA Pocket Guide 2021.",
	material_sati_7:"7- GESEPOC2021.",
	material_sati_8:"8- Consenso asma pediatría 2021 REGAP.",
	material_sati_9:"9- Dossier formativo.",
	material_sati_10:"10- Algoritmo de resistencias de In-Check Dial.",
	material_sati_11:"11- Tabla de flujos mínimos y óptimos por dispositivo.",
	material_sati_12:"12- Pauta InmunoRespir.",
	material_sati_13:"13- Ofrecimiento del servicio SATI.",
	material_sati_14:"14- Cuestionario para descartar Covid-19.",
	material_sati_15:"15- Proveedores de productos SATI para farmacias.",
	material_sati_16:"16- Modelo solicitud ejercicio derechos del interesado.",
	material_sati_17:"17- Consentimiento cesión imágenes y vídeos pacientes SATI.",
	material_sati_18:"18- Instrucciones solicitudes del interesado en la Farmacia.",
	material_sati_19:"19- Informe del paciente.",
	material_sati_20:"20- Informe de derivación básico.",
	material_sati_21:"21- Informe de derivación por flujo.",
	material_sati_22:"22- Infografia asma y colageno.",
	material_sati_23:"23- Tabla de flujos óptimos por dispositivo.",
	material_sati_24:"24- Elección inhalador principal. Castellano",
	material_sati_25:"25- Pauta InmunoRespir.",
	material_sati_26:"26- Quecetina.",
	material_sati_27:"27- Mapa mental 3 acciones inhaladores.",

	file_sati_1:"https://www.farmaval.net/docs/1-Estudio%20EPOCONSUL.pdf",
	file_sati_2:"https://www.farmaval.net/docs/2-Informe%20de%20resultados%20ASPIRA%202014.pdf",
	file_sati_3:"https://www.farmaval.net/docs/3-%20Revisi%C3%B3n%20sistematica%202016%20sobre%20errores%20en%20terapia%20inhalada.pdf",
	file_sati_4:"https://www.farmaval.net/docs/4-%20Programa%20SABINA%20sobreuso%20SABA.pdf",
	file_sati_5:"https://www.farmaval.net/docs/5-%20MiniGEMA5.1.pdf",
	file_sati_6:"https://www.farmaval.net/docs/6-%20GINA%20Pocket%20Guide%202021.pdf",
	file_sati_7:"https://www.farmaval.net/docs/7-%20GESEPOC2021.pdf",
	file_sati_8:"https://www.farmaval.net/docs/8-%20Consenso%20asma%20pediatria%202021%20REGAP.pdf",
	file_sati_9:"https://www.farmaval.net/docs/9-Dossier%20formativo.pdf",
	file_sati_10:"https://www.farmaval.net/docs/10-%20Algoritmo%20de%20resistencias%20de%20In-Check%20Dial.pdf",
	file_sati_11:"https://www.farmaval.net/docs/11-%20Tabla%20de%20flujos%20m%C3%ADnimos%20y%20%C3%B3ptimos%20por%20dispositivo.pdf",
	file_sati_12:"https://www.farmaval.net/docs/12-%20Pauta%20InmunoRespir.pdf",
	file_sati_13:"https://www.farmaval.net/docs/13-%20Oferiment%20del%20servei%20SATI.pdf",
	file_sati_14:"https://www.farmaval.net/docs/14-%20Cuestionario%20para%20descartar%20Covid-19.pdf",
	file_sati_15:"https://www.farmaval.net/docs/15-%20Proveedores%20de%20productos%20SATI%20para%20farmacias.pdf",
	file_sati_16:"https://www.farmaval.net/docs/16-%20Modelo%20solicitud%20ejercicio%20derechos%20del%20interesado.pdf",
	file_sati_17:"https://www.farmaval.net/docs/17-Consentimiento%20cesi%C3%B3n%20imagenes%20y%20videos%20pacientes%20SATI%20.pdf",
	file_sati_18:"https://www.farmaval.net/docs/18-Instrucciones%20solicitudes%20del%20intereado%20en%20la%20Farmacia.pdf",
	file_sati_19:"https://www.farmaval.net/docs/19-Informe%20del%20paciente.pdf",
	file_sati_20:"https://www.farmaval.net/docs/20-%20Informe%20de%20derivaci%C3%B3n%20b%C3%A1sico.pdf",
	file_sati_21:"https://www.farmaval.net/docs/21-%20Informe%20de%20derivaci%C3%B3n%20por%20flujo.pdf",
	file_sati_22:"https://www.farmaval.net/docs/22-%20Infografia%20asma%20i%20colagen.pdf",
	file_sati_23:"https://www.farmaval.net/docs/23-%20Taula%20de%20fluxos%20%C3%B2ptims%20per%20dispositiu.pdf",
	file_sati_24:"https://www.farmaval.net/docs/24-%20Elecci%C3%B3%20inhalador%20principal.pdf",
	file_sati_25:"https://www.farmaval.net/docs/25-%20Pauta%20InmunoRespir%20(cat).pdf",
	file_sati_26:"https://www.farmaval.net/docs/26-%20Quecetina.pdf",
	file_sati_27:"https://www.farmaval.net/docs/27-%20Mapa%20mental%203%20acciones%20inhaladores.pdf",

	// Calendario 
	calendar_dates:"Cites",
	calendar_follow:"Seguimient",
	day_1:"Dl",
	day_2:"Dm",
	day_3:"Dm",
	day_4:"Dj",
	day_5:"Dv",
	day_6:"Ds",
	day_7:"Dg",
	calendar_months:"Gener,Febrer,Març,Abril,Maig,Juny,Juliol,Agost,Setembre,Octubre,Novembre,Desembre",	
	date_btn_add:"Afegir cita",	
	date_btn_update:"Actualitzar",
	date_btn_delete:"Eliminar",
	date_confirm_delete:"Escrigui <b>eliminar cita</b> i premi 'Eliminar':",
	date_confirm_delete_text:"eliminar cita",

	date_no_shift:"No n'hi ha",

	date_popup_title:"Programar cita",
	date_lbl_patient:"Pacient",
	date_lbl_staff:"Encarregat/da",
	date_lbl_service:"Servei",
	date_lbl_date:"Data",
	date_lbl_time:"Horari",
	date_lbl_phone:"Telèfon",
	date_lbl_comment:"Comentari",
	date_btn_cancel:"Cancel·lar",

	// General
	result_ok:"Operació realitzada",
	error_empty_fields:"Dades incorrectes",
	error_operation:"Error a l'operació",
	
	error_params:"100",
	error_query:"101",

	btn_new:"Nou",
	btn_add:"Afegir",
	btn_update:"Actualitzar",
	btn_delete:"Eliminar",

	// Pending
	pending_info:"Hi ha documentació que ha de ser revisada y signada abans d’iniciar el servei i tenir accés a totes les funcions de l’aplicació.<br><br>Si us plau, ompli tots els camps dels següents formularis i revisi i signi els documents:",
	pending_data:"Documents per revisar i signar",
	pending_agreement_service_form:"Dades implantació",
	pending_agreement_sepa_form:"Dades SEPA",
	pending_agreement_service:"Contracte d’ implantació",
	pending_agreement_service_info:"Termes i condicions del servei.",
	pending_agreement_sepa:"Ordre de domiciliació SEPA",
	pending_agreement_sepa_info:"Document per a què el seu banc accepti els rebuts emesos per Farmaval.",
	pending_type_admin:"Administrador",
	pending_type_owner:"Titular",
	pending_lbl_pharm_name:"Nom de la farmàcia",
	pending_lbl_address:"Adreça",
	pending_lbl_city:"Municipi",
	pending_lbl_state:"Comunitat autònoma",
	pending_lbl_nif:"CIF o NIF d’empresa",
	pending_lbl_signer_type:"En qualitat de: ",
	pending_lbl_signer_dni:"DNI",
	pending_lbl_signer_name:"Nom del signant",

	pending_lbl_debtor_name:"Nom del deutor",
	pending_lbl_debtor_city:"Població",
	pending_lbl_debtor_state:"Província",
	pending_lbl_debtor_country:"País",
	pending_lbl_debtor_swift:"Swift BIC",
	pending_lbl_debtor_iban:"Número de compte-IBAN",

	btn_pending_service:"Revisar i signar documents",
	btn_pending_sepa:"Revisar i signar ordre",

	pending_months:"de Gener,de Febrer,de Març,d'Abril,de Maig,de Juny,de Juliol,d'Agost,de Setembre,d'Octubre,de Novembre,de Desembre",	

	pending_upload:"Enviar documentació",
	pending_again:"Tornar a signar",

	checking_data:"Documents en revisió",
	checking_msg:"S'està revisant la documentació.<br><br>Tan aviat com estiguin confirmades les dades, se us avisarà per començar amb la formació  de l'equip.<br><br>Gràcies.",
	

	// Profile
	settings_title_info:"Información",	
	settings_title_admin:"Titular",	
	settings_title_pharm:"Dades",	
	settings_title_staff:"Personal",	
	settings_title_drugs:"Productes",	
	settings_title_calendar:"Dies de servei",	
	settings_title_syncro:"Sincronització",	

	// Personal
	staff_lbl_name:"Nom",
	staff_lbl_title:"Carrrec",
	staff_lbl_code:"Nº colegiat",
	staff_lbl_date:"Data",
	staff_lbl_comment:"Comentari",
	staff_lbl_active:"Estat",
	staff_lbl_sati:"SATI",

	staff_title_0:"Auxiliar",
	staff_title_1:"Tècnic",
	staff_title_2:"Farmacèutic",
	staff_title_3:"Titular",
	staff_title_4:"Altre",
	staff_active_0:"Inactiu",
	staff_active_1:"Actiu",

	staff_sati_0:"No",
	staff_sati_1:"Sí",

	staff_confirm_delete:"Escrigui <b>eliminar personal</b> i premi 'Eliminar':",
	staff_confirm_delete_text:"eliminar personal",

	// Index config days
	day_lbl_name:"Dia",
	day_lbl_from:"Des de",
	day_lbl_to:"Fins",
	day_name_0:"Dilluns",
	day_name_1:"Dimarts", 
	day_name_2:"Dimecres",
	day_name_3:"Dijous",
	day_name_4:"Divendres",
	day_name_5:"Dissabte",
	day_name_6:"Diumenge",
	day_confirm_delete:"Escrigui <b>eliminar dia</b> i premi 'Eliminar':",
	day_confirm_delete_text:"eliminar día",

	// productos
	prod_lbl_name:"Nom",
	prod_lbl_info:"Informació",
	prod_lbl_price:"Preu",
	prod_lbl_service:"Servei",
	prod_lbl_creator:"Creador",
	prod_lbl_state:"Estat",
	prod_state_0:"Inactiu",
	prod_state_1:"Actiu",
	prod_confirm_delete:"Escrigui <b>eliminar producte</b> i premi 'Eliminar':",
	prod_confirm_delete_text:"eliminar producte",

	// farmacia
	pharm_lbl_name:"Nom",
	pharm_lbl_business:"Raó social",
	pharm_lbl_nif:"CIF",
	pharm_lbl_address:"Direcció",
	pharm_lbl_city:"Ciutat",
	pharm_lbl_state:"Provincia",
	pharm_lbl_postal:"Codi postal",
	pharm_lbl_email:"Email",
	pharm_lbl_phone:"Telèfon fix",
	pharm_lbl_mobile:"Telèfon mònil",
	pharm_lbl_web:"Web",

	pharm_lbl_admin_name:"Nom",
	pharm_lbl_admin_code:"Nº col·legiat",
	pharm_lbl_admin_email:"Email",
	pharm_lbl_admin_signature:"Signatura",
	pharm_lbl_admin_password:"Contrasenya",
	
	pharm_lbl_user:"Usuari",
	pharm_lbl_password:"Contrasenya",
	pharm_password_empty:"Deixar buit per a no canviar",
		
	pharm_lbl_language:"Idioma",
	pharm_lbl_password:"Contrasenya",
	pharm_lbl_logo:"Logo pels informes (aspecte 3/1)",
	pharm_lbl_schedule:"Horari",
	
	pharm_lbl_schedule_0:"Ininterromput",
	pharm_lbl_schedule_1:"Partit",
	pharm_lbl_schedule_2:"24 hores",

	
	day_btn_add:"Afegir horari",
	day_btn_delete:"Eliminar",
	question_remove_day:"Eliminar horari de servei: ",
	error_loading_days:"Problema en carregar dies disponibles.",	
	error_removing_day:"No s’ha pogut eliminar l’horari.",
	error_adding_day:"No s’ha pogut afegir l’horari.",

	// PDF viewer	
	pdf_close:"Tancar informe",	
	
	// Popup
	popup_ok:"Acceptar",
	popup_close:"Tancar",
	popup_cancel:"Cancel·lar",	
	
	// Index
	new_link_more:"Més informació",
	
	// Index config products
	products_lbl_list:"Productes disponibles.",	
	products_lbl_service:"Servei.",
	error_loading_products:"Problema en carregar els productes.",	
	product_lbl_name:"Producte",
	product_lbl_info:"Informació",
	product_lbl_cost:"Preu",
	product_lbl_cn:"CN",	
	
	product_btn_add:"Afegir",
	product_btn_edit:"Modificar",
	product_btn_delete:"Esborrar",
	
	product_msg_empty:"Cal emplenar-ne tots els camps.",
	product_msg_cost:"El preu no és un valor correcte.",
	product_msg_error:"S’ha produït un problema en realitzar l’operació.",
		
	product_question_edit:"Vol modificar el producte seleccionat?",
	product_question_delete:"Vol eliminar el producte seleccionat?",
	
	
	
	// Index calendar
	calendar_days:"Dl,Dm,Dm,Dj,Dv,Ds,Dg",
	calendar_months:"Gener,Febrer,Març,Abril,Maig,Juny,Juliol,Agost,Setembre,Octubre,Novembre,Desembre",	
	calendar_times:"Horaris: ",
	calendar_no_times:"No n’hi ha",
	
	calendar_date_title:"Cites del dia ",
	calendar_no_dates:"No hi ha cites",
	btn_cancel_date:"cancel·lar",
	btn_edit_date:"editar",
	lbl_date_phones:"Tels:",
	new_date_text:"Afegir cita el dia:",
	new_date_lbl_patient:"Pacient",
	new_date_lbl_staff:"Tècnic/a",
	new_date_lbl_service:"Servei",
	new_date_lbl_date:"Data",
	new_date_lbl_time:"Horari",
	new_date_lbl_phone:"Telèfon",
	new_date_lbl_comment:"Comentari",
	new_date_error_empty:"Falten dades per emplenar",
	new_date_error_server:"Error en afegir la cita",
	new_date_confirm_remove:"Eliminar cita de ",
	error_loading_date:"Error carregant dades",
	
	// News y Mensajes
	error_loading_news:"No s’ha pogut carregar les notícies.",	
	lbl_loading_news:"Carregant notícies...",
	title_news:"Notícies",
	//title_chat:"Contacte",

	// Reports
	reports_list:"Serveis",
	reports_staff:"Personal",
	reports_stats:"Informes SATI",
	reports_stats_iretia:"Informes IRETIA",

	lbl_report_staff_name:"Nom",
	lbl_report_staff_SATI:"SATI",
	lbl_report_staff_IRETIA:"IRETIA",
	lbl_report_staff_CEPOC:"CEPOC",
	lbl_report_staff_dates_count:"Cites taulell",
	lbl_report_staff_dates_service_count:"Cites servei",
	lbl_report_staff_last_service:"Últim servei",
	lbl_report_staff_last_date:"Última cita",
	lbl_report_staff_state:"Estat",
	lbl_report_staff_state_active:"Actiu",
	lbl_report_staff_state_noactive:"Inactiu",

	lbl_report_type:"Servei",
    lbl_report_date:"Data",
    lbl_report_name:"Nom",
	lbl_report_staff:"Tècnic/a",
	lbl_report_sales:"Vendes",
	lbl_report_main:"Inhalador",
	lbl_report_visit:"Visita In.",
	lbl_report_visit_real:"Visita",	

	filter_service:"Servei",
	filter_from:"Des de",
	filter_to:"Fins",
	filter_patient:"Cognoms pacient",
	filter_btn:"Mostrar",
	filter_type_all:"Tots",
	filter_type_sati:"SATI",
	filter_type_iretia:"IRETIA",
	filter_type_cepoc:"CEPOC",
	filter_type_deleted:"Eliminats",

	report_popup_title:"Editar servicio",
	report_lbl_comment:"Comentario",
	report_lbl_sales:"Ventas",
	report_lbl_product_type:"Producto",
	report_lbl_product_name:"Nombre",
	report_lbl_product_cost:"Coste",
	report_lbl_product_quantiy:"Cantidad",

	report_btn_update:"Actualitzar",
	report_btn_recover:"Recuperar",
	report_btn_delete:"Eliminar",
	report_btn_remove:"Eliminar definitivament",
	report_btn_cancel:"Cancel·lar",

	report_btn_view_patient:"Informe del pacient",
	report_btn_view_doctor:"Informe derivació metge",

	report_confirm_delete:"Introduïu <b>eliminar servei</b> i feu clic a 'Elimina':",
	report_confirm_delete_text:"eliminar servei",

	report_confirm_remove:"Introduïu <b>eliminar definitivament</b> i feu clic a 'Eliminar definitivament':",
	report_confirm_remove_text:"eliminar definitivament",
	
	report_sale_remove:"Treure",
	report_sale_add:"Afegir",

	// Pacientes
	
	patients_list:"Consulta",
	filter_patient_name:"Cognoms del pacient",
	filter_patient_order:"Ordenar por",
	filter_patient_order_name:"Cognoms",
	filter_patient_order_next_visit:"Propera visita",
	
	col_patient_name:"Pacient",
	col_patient_com_mode:"Comunicacions",
	col_patient_contact:"Contacte",
	col_patient_lng:"Idioma",
	col_patient_visits:"Visites",
	col_patient_next_visit:"Propera visita",
	patient_contact_email:"Email",
	patient_contact_call:"Trucada",
	patient_contact_sms:"SMS",

	patient_tab_basic:"Dades bàsiques",
	patient_tab_com:"Comunicacions",
	patient_tab_clinic:"Metge",
	
	patient_popup_title:"Editar pacient",
	patient_lbl_dni:"DNI",
	patient_lbl_name:"Nom",
	patient_lbl_surname_1:"Primer cognom",
	patient_lbl_surname_2:"Segon cognom",
	patient_lbl_date:"Data de naixement",
	patient_lbl_sex:"Sexe",
	patient_lbl_smoker:"Fumador",
	patient_lbl_town:"Població",
	patient_lbl_postal:"Codi postal",
	patient_lbl_com_mode:"Comunicacions",
	patient_lbl_phone:"Telèfon (trucada)",
	patient_lbl_mobile:"Telèfon (SMS)",
	patient_lbl_email:"Email",
	patient_lbl_language:"Idioma",
	patient_lbl_doctor_genere:"Dr./Dra.",
	patient_lbl_doctor:"Metge de capçalera",
	patient_lbl_center:"Centre mèdic",
	patient_lbl_comment:"Possibles substitucions a la derivació per flux",

	patient_value_yes:"Sí",
	patient_value_no:"No",
	patient_value_man:"Masculí",
	patient_value_woman:"Femení",
	patient_value_dr:"Doctor",
	patient_value_dra:"Doctora",

	patient_btn_delete:"Eliminar pacient",
	patient_btn_new:"Nou pacient",
	patient_btn_add:"Registrar pacient",
	patient_btn_update:"Actualitzar dades",
	patient_btn_cancel:"Cancel·lar",

	patient_confirm_delete:"Atenció! Utilitzeu aquesta opció només si sou un pacient de proves. Per confirmar, escriviu <b>eliminar pacient</b> i premeu 'Eliminar':",
	patient_confirm_delete_text:"eliminar pacient",

	// Sincronización

	syncro_lbl_data:"Dades emmagatzemades localment",
	syncro_lbl_value:"Total",
	syncro_data_staff:"Personal farmàcia",
	syncro_data_drugs:"Medicaments",
	syncro_data_days:"Dies de servei",
	syncro_data_products:"Productes",
	syncro_data_services:"Serveis",
	
	syncro_lbl_date:"Última actualització",

	syncro_lbl_services:"Llista de serveis per a sincronitzar",
	syncro_data_services_none:"Cap servei",
	btn_syncro_now:"Sincronitzar ara",

	
	// stats
	btn_stats_print:"Imprimir",
	stats_title_report:"Servei d'Acompanyament a Teràpia Inhalada",
	stats_from:"Informes des de ",
	stats_to:" fins a ",
	stats_financial_title:"Dades financeres econòmiques",
	stats_visit_all:"Totes les visites",
	stats_visit_1:"Primera visita",
	stats_visit_2:"Segona visita",
	stats_visit_3:"Visites de seguimient",
	stats_title_services:"Serveis",
	stats_title_time:"Duraada",
	stats_title_time_per:"Durada per servei",
	stats_title_sales:"Vendes",
	stats_title_sales_per:"Vendes per servei",
	stats_prescription:"Prescripcions",
	stats_title_prescription:"Serveis amb almenys una prescripió",
	stats_title_prescription_per:"% de serveis",
	stats_sales:"Productes venuts",
	stats_title_quantity:"Quantitat",
	stats_title_total:"Total",
	stats_all_staff:"Total",
	stats_farmaval:"Mitjana Farmaval",
	
	chart_services:"SERVEIS TOTALS",
	chart_time_avg:"TEMPS MITJA PER SERVEI",
	chart_prescriptions:"PRESCRIPCIONS",
	chart_prescriptions_service:"PRESCRIPCIONS PER SERVEI",
	chart_media:"Farmaval: Mitjana",
	chart_best:"Farmaval: Millors",
	chart_pharm:"Farmàcia",
	chart_visits:"Totes les visites",
	chart_visit_1:"Primera visita",
	chart_visit_2:"Segona visita",
	chart_visit_3:"Visites de seguimient",


	stats_medical_title:"Dades assistencials",
	stats_technique:"Millora a la tècnica inhalada",
	stats_asma:"Dades d'asma",
	stats_asma_kids:"Dades d'asma infantil",
	stats_epoc:"Dades de MPOC",
	stats_aco:"Dades d'ACO",

	stats_medical_services:"Nombre de serveis",
	stats_points_asma:"Puntuació mitjana<br><small>5 mal control -> 25 bon control</small>",
	stats_points_asma_kids:"Puntuació mitjana<br><small>0 mal control -> 27 bon control</small>",
	stats_points_epoc:"Puntuació mitjana<br><small>40 impacte molt alt -> 0 impacte baix</small>",
	stats_points_aco:"Puntuació mitjana<br><small>40 impacte molt alt -> 0 impacte baix</small>",
	stats_control_perfect:"Control total",
	stats_control_good:"Control bo",
	stats_control_bad:"No controlat",
	stats_control_ok:"Controlat",
	stats_control_ko:"No controlat",

	stats_impact_low:"Impacte baix",
	stats_impact_middle:"Impacte mitjà",
	stats_impact_high:"Impacte alt",
	stats_impact_higher:"Impacte molt alt",

	stats_errors:"Errors més comuns",

	stats_medical_visits:"Visites",
	stats_medical_visit_1:"Visita 1a",
	stats_medical_visit_2:"Visita 2a",
	stats_medical_visit_3:"Visita >2a",
	stats_medical_improvment:"Millora",
	stats_medical_quantity:"Quantitat",

	stats_medical_info:"Els càlculs de control de malaltia es realitzen només amb les dades de les tres primeres visites. Els resultats de les següents visites de seguiment no es tenen en compte.",

	stats_technique_pharm:"Mitja d'errors en tècnica",
	stats_technique_media:"Mitja Farmaval",

// stats IRETIA
stats_pharm:"Farmàcia",
stats_farmaval:"Farmaval",
stats_iretia_title_report:"Servei d'intervenció ràpida en teràpia inhalada aguda",

stats_iretia_title_1:"Dades generals",
stats_iretia_title_2:"Perfil pacients",
stats_iretia_title_3:"Diagnòstics",
stats_iretia_title_4:"Inahaladors més dispensats",
stats_iretia_title_5:"Errors més comuns",
stats_iretia_title_6:"Prestació de servei per membre de l'equip",

stats_iretia_col_1:"La meva farmàcia",
stats_iretia_col_2:"Mitja farmàcies Farmaval",
stats_iretia_col_3:"Farmàcia més activa",

stats_iretia_1:"Nombre serveis IRETIA prestats",
stats_iretia_2:"Temps mitjà invertit per servei (minuts)",
stats_iretia_3:"Total vendes associades servei",
stats_iretia_4:"Mitja de vendes per servei",
stats_iretia_5:"Serveis amb vendes associades",
stats_iretia_6:"Mitja d'errors",
stats_iretia_7:"Serveis amb almenys 1 error en la tècnica",
stats_iretia_8:"Valoració molt útil del servei",
stats_iretia_9:"Valoració compromís molt alt de la farmàcia",

stats_iretia_8_3:"No ho sap",
stats_iretia_8_0:"Bronquitis aguda",
stats_iretia_8_7:"Bronquitis asmàtica",
stats_iretia_8_1:"Pneumònia",
stats_iretia_8_2:"Covid o Post Covid",
stats_iretia_8_5:"Cardiovascular",
stats_iretia_8_9:"Refredat comú",
stats_iretia_8_10:"Grip",
stats_iretia_8_6:"Un altre",

stats_iretia_9_1:"Dones",
stats_iretia_9_2:"Mitja edat",
stats_iretia_9_3:"Fumadors",
stats_iretia_9_4:"Ex fumador",
stats_iretia_9_5:"Fumador passiu",
stats_iretia_9_6:"No fumador",

stats_iretia_first:"1r",
stats_iretia_second:"2n",
stats_iretia_third:"3r",

stats_iretia_team_1:"Serveis",
stats_iretia_team_2:"Temps mitjà",
stats_iretia_team_3:"Vendes",
stats_iretia_team_4:"Mitja de vendes",
stats_iretia_team_5:"Serveis amb vendes",

stats_iretia_products:"Productos venuts",
stats_iretia_product_qty:"Quantitat",
stats_iretia_product_total:"Total",


	// Settings

	settings_info_drugs:"Gestió de productes recomanats pels serveis.",	
	settings_info_calendar:"Selecció de dies hàbils per defecte per a la realització de serveis.",
	settings_info_syncro:"Informació sobre les dades desades localment.",	
		
	settings_info_pharm:"Opcions generals relatives a l’aplicació i a la farmàcia.",	
	settings_lbl_signature:"Signatura del/de la titular",
	settings_lbl_version:"Versió de l’aplicació",
	settings_lbl_pharm:"Farmàcia",
	settings_lbl_disabled:"En desenvolupament",
	settings_lbl_noactive:"No activat",
	error_loading_pharm:"Problema mentre s’hi carregaven les dades de la farmàcia",
	
	no_signature_msg:"El sistema no disposa d’una signatura del titular de la farmàcia. És necessària per signar els informes.<br><br/>Es pot establir des de la secció de configuració.", 	
	no_passwords_msg:"Falta contrasenya d’accés. El sistema no és segur. Si us plau, estableixi la contrasenya des de la secció de configuració.", 	
	no_signature_passwords_msg:"El sistema no disposa d’una signatura del titular de la farmàcia (necessària per signar els informes) ni tampoc de contrasenya d’accès.<br><br/>Es poden establir des de la secció de configuració.", 	

	settings_btn_new_signature:"Nova signatura",
	signature_pharm_title:"Signatura del titular de la farmàcia",
	
	settings_password_public_lbl:"Contrasenya d'access",
	settings_password_public_info:"Està associada a l’usuari/l’adreça de correu electrònic de la farmàcia i permet l'acceés a l'aplicatiu i realitzar serveis.",
	warning_nopassword:"Atenció! No n’hi ha, de contrasenya.",
	btn_new_password:"Establir-ne contrasenya",
	btn_change_password:"Canviar-ne contrasenya",
	new_password_must_1:"Més de 7 caràcters.",
	new_password_must_2:"Incloure lletres.",
	new_password_must_3:"Incloure números.",
	new_password_must_4:"Incloure símbols.",
	new_password_must_5:"Confirmar-ne la contrasenya.",
	new_password_value:"Nova contrasenya",
	new_password_repeat:"Confirmar-ne la contrasenya.",
	new_password_public_title:"Canviar la contrasenya dels tècnics.",
	new_password_error:"Error en intentar assignar la contrasenya. Si us plau, intenti-ho novament.",
	new_password_notallowed:"Un usuari tècnic no pot canviar les contrasenyes. Si us plau, iniciï sessió com a administrador.",

	// Service 
	signature_title_modal:"Recollida de dades per la prestació del servei SATI",
	signature_text_check1:"AUTORITZO l’ús de les meves dades per a la prestació del Servei en Acompanyament en Teràpia Inhalada.",
	signature_text_check2:"AUTORITZO l’ús de les meves dades per a la realització de comunicacions telemàtiques (SMS, missatges, adreça de correu electrònic, etc.)",
	signature_text_check3:"AUTORITZO l’ús de les meves dades per a la realització d’estudis d’investigació assistencial.",
	signature_text_check4:"AUTORITZO a la farmàcia a compartir amb el metge que em tracta la informació sobre el tractament inhalat que prenc per tal de millorar els meus resultats terapèutics.",

	signature_image_title_modal:"Consentiment explícit per a la publicació i el tractament d'imatges general",
	signature_image_radio1:"AUTORITZO l’ús de les imatges.",
	signature_image_radio2:"NO AUTORITZO l’ús de les imatges",


	signature_label_patient:"En ser menor, escrigui el nom de la persona responsable.",
	signature_btn_clear:"Començar de nou",
	signature_btn_ok:"Acceptar",
	signature_btn_cancel:"Cancel·lar",
	signature_error:"No ha estat possible emmagatzemar la nova signatura. Si us plau, intenti-ho novament.",
	
	service_error_unknown:"El servei no existeix",
	service_error_loading:"Error en carregar els serveis",
	service_error_patient:"Error en carregar el formulari del pacient",
	service_error_staff:"Error en carregar les dades dels tècnics",
	service_error_service:"Error en carregar les dades del servei",
	
    service_btn_cancel:"Cancel·lar el servei",
	service_btn_prev:"Enrere",
	service_btn_next:"Següent",
	tab_start_title:"Inici del servei",
	tab_start_info:"Seleccioni el tècnic que realitzarà el servei i empleni la fitxa del pacient. Si el pacient ja està registrat, el pot buscar pel DNI o els seus cognoms.",
	tab_start_info_iretia:"Seleccioni el tècnic que realitzarà el servei i empleni les dades del pacient. En cas de cessió de drets d'imatge, empleni DNI, nom i cognoms.",
	tab_start_staff_info:"Tècnic del servei",

	lbl_question_cancel:"Està segur/a que vol cancel·lar-ne el servei? No s’hi guardarà cap dada.",

    lbl_question_new:"Escrigui el nom del nou tècnic:",
    lbl_question_update:" Escrigui el nou nom del tècnic:",
    lbl_question_delete:"Està segur/a que hi vol eliminar el tècnic seleccionat?",
   
	btn_staff_delete:"Eliminar seleccionat",	
	btn_staff_update:"Modificar",	
	btn_staff_new:"Nou",	
	
	tab_start_patient_info:"Fitxa del pacient",
	lbl_patient_search:"Cercant pacient...",
	lbl_patient_new:"Nou pacient",
	lbl_patient_error:"Error en el servidor",
	lbl_patient_dni:"DNI",
	lbl_patient_name:"Nom",
	lbl_patient_surname_1:"Primer cognom",
	lbl_patient_surname_2:"Segon cognom",
	lbl_patient_date:"Data de naixement",
	lbl_patient_age:"Edat",
	lbl_patient_sex:"Sexe",
	lbl_patient_smoke:"Fumador",
	
	lbl_patient_extra:"Dades si el pacient cedeix els drets d'imatge",

	options_patient_sex:"Dona/1,Home/0",
	options_patient_smoke:"No/0,Sí/1",
	
	lbl_patient_studies:"Estudis",
	lbl_patient_tsi:"TSI",
	options_patient_studies:"Sense estudis/0,Bàsics/1,Batxillerat/2,Grau tècnic/3,Universitaris/4",
	
	lbl_patient_town:"Població",
	lbl_patient_postal:"Codi postal",
	lbl_patient_phone:"Telèfon fix (trucada)",
	lbl_patient_mobile:"Telèfon mòbil (SMS)",
	lbl_patient_contact:"Telèfon mòbil",
	lbl_patient_email:"Correu electrònic",
	lbl_patient_com_mode:"Comunicacions",
	lbl_patient_lang:"Idioma",
	options_patient_com_mode:"Per correu electrònic/0,Per trucada/1,Per SMS/2",
	options_patient_lang:"Català/ca,Castellano/es",
	
	lbl_patient_doctor_genere:"Dr./Dra.",
	options_patient_doctor_genere:"Dr./0,Dra./1",
	lbl_patient_doctor:"Metge de capçalera",
	lbl_patient_center:"Centre mèdic",
	lbl_patient_comment:"Possibles substitucions a la derivació per flux",
	
	lbl_patient_sign:"Signatura del pacient:",
	
	section_patient_title:"Dades bàsiques del pacient:",
	section_patient_info:"Empleni les dades si és un nou pacient o actualitzi-les si cal.",
	
	form_error_staff:"Seleccioni el seu nom com a tècnic del servei.",
	form_error_required:"Falten dades per emplenar.",
	form_error_pattern:"El format no és el correcte.",
	form_error_contact:"Ha d’indicar la dada de contacte.",
	form_error_options:"Hi ha preguntes del qüestionari sense respondre.",
	
	// 0.7.3
	title_chat:"Contacte",
    info_chat:"Pots enviar-nos un correu directament des d’aquí.",
    placeholder_chat:"Missatge a enviar",
    btn_chat_send:"Enviar",
    email_ok:"Gràcies, et respondrem al més aviat possible.",
    email_error:"No hem pogut enviar el correu, si-us-plau, intenta-ho novament o bé posa’t en contacte amb nosaltres a través de farmaval.net",

	// 0.7.5
	rep_title_change:"Servei a: ",
    rep_comment:"Comentari",
    rep_btn_patient:"Informe pacient",
    rep_btn_doctor:"Informe mèdic",
    rep_btn_delete:"Eliminar servei",
	rep_btn_remove:"Eliminar definitivament",
    rep_btn_confirm:"Confirmar eliminar",
    rep_btn_save:"Actualitzar",
	rep_btn_restore:"Restaurar",
    rep_sales:"Productes venuts",
	rep_sale_remove:"Eliminar",
    rep_products:"Productes",
    rep_prod_name:"Nom",
    rep_prod_cost:"Preu",
    rep_prod_qty:"Quantitat",
    rep_prod_add:">>",
    rep_prod_other:"Altre",
    rep_result_error:"Error realitzant operació",
    rep_result_notallowed:"Únicament el titular pot eliminar registres.",


	service_error_loading_data:"Hi ha hagut un error actualitzant les dades per iniciar el servei.",
	service_error_local_data:"No s'han pogut carregar dades per iniciar el servei. Assegureu-vos que hi ha connexió a Internet i torneu a intentar-ho.",
	service_warning_local_data:"És possible que les dades per iniciar el servei estiguin desactualitzades.",

	service_online:"Conectat",
	service_offline:"Sense conexió",

	end_reports_question:"Confirmar la finalització del servei.",
	end_error_reports:"Problema en generar informes i emmagatzemar-ne el servei.",
	end_error_reports_backup:"Problema en desar el servei. S'emmagatzemarà de forma local per no perdre les dades.<br><br>Podeu realitzar la sincronització amb el servidor des de la secció de configuració.",

};
